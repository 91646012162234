@font-face {
  font-family: 'IQOS';
  src: url('../../fonts/Iqos/IQOS-Light.woff2?2') format('woff2'),
    url('../../fonts/Iqos/IQOS-Light.woff?2') format('woff');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'IQOS';
  src: url('../../fonts/Iqos/IQOS-Regular.woff2?2') format('woff2'),
    url('../../fonts/Iqos/IQOS-Regular.woff?2') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'IQOS';
  src: url('../../fonts/Iqos/IQOS-Bold.woff2?2') format('woff2'),
    url('../../fonts/Iqos/IQOS-Bold.woff?2') format('woff');
  font-weight: 700;
  font-display: swap;
}
